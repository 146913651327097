import React from "react"

const Cubes = () => {
  return (
    <div className="cubes-icons">
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 600"
      >
        <path d="M568.4,308.9l-18.1-10.5l16.5-9.5c12.9-7.4,23-24.9,23-39.8v-98.4c0-14.9-10.1-32.3-23-39.8l-85.2-49.2 c-6.2-3.6-14.4-5.6-23-5.6c-8.6,0-16.7,2-23,5.6L350.4,111c-12.9,7.4-23,24.9-23,39.8v98.4c0,14.9,10.1,32.3,23,39.8l18.1,10.5 l-16.5,9.5c-12.9,7.4-23,24.9-23,39.8v98.4c0,14.9,10.1,32.3,23,39.8l85.2,49.2c6.2,3.6,14.4,5.6,23,5.6c8.6,0,16.7-2,23-5.6 l85.2-49.2c12.9-7.4,23-24.9,23-39.8v-98.4C591.4,333.8,581.3,316.3,568.4,308.9z M571.7,142.1c1.1,3,1.7,5.9,1.7,8.6v98.4 c0,9.1-6.9,21.1-14.8,25.6L534,289l-63.9,37.6l-1.6-124.9L571.7,142.1z M358.6,125.1l85.2-49.2c3.7-2.2,9.1-3.4,14.8-3.4 c5.7,0,11.1,1.2,14.8,3.4l85.2,49.2c0.5,0.3,1,0.6,1.5,1l-101.5,58.6l-101.5-58.6C357.6,125.7,358.1,125.4,358.6,125.1z M358.6,275.5c-7.9-4.5-14.8-16.5-14.8-25.6v-98.4c0,0,0,0,0,0v-0.7c0-2.7,0.6-5.7,1.7-8.6l103.3,59.6v53.6h0v71.3 c0,0-1.2,0.2-5-2L358.6,275.5z M450.4,523.9c0,0-1.2,0.2-5-2l-85.2-49.2c-7.9-4.5-14.8-16.5-14.8-25.6v-98.4 c0-2.7,0.6-5.7,1.7-8.6l103.3,59.6V523.9z M460.2,382.6L358.7,324c0.5-0.3,1-0.7,1.5-1l24.6-14.2l50.8,29.3 c6.2,3.6,14.4,5.6,23,5.6c8.6,0,16.7-2,23-5.6l52.4-30.3l26.3,15.2c0.5,0.3,1,0.6,1.5,1L460.2,382.6z M575.1,447.1 c0,9.1-6.9,21.1-14.8,25.6L475,521.9c-1.4,0.8-3.1,1.5-5,2V399.7L573.4,340c1.1,3,1.7,5.9,1.7,8.6V447.1z" />
        <path d="M270.7,311.1l-85.2-49.2c-6.2-3.6-14.4-5.6-23-5.6c-8.6,0-16.7,2-23,5.6l-85.2,49.2c-12.9,7.4-23,24.9-23,39.8v98.4 c0,14.9,10.1,32.3,23,39.8l85.2,49.2c6.2,3.6,14.4,5.6,23,5.6c8.6,0,16.7-2,23-5.6l85.2-49.2c12.9-7.4,23-24.9,23-39.8v-98.4 C293.7,336,283.6,318.5,270.7,311.1z M152.7,526c0,0-1.2,0.2-5-2l-85.2-49.2c-7.9-4.5-14.8-16.5-14.8-25.6v-98.4 c0-2.7,0.6-5.7,1.7-8.6l103.3,59.6V526z M162.5,384.8L61,326.2c0.5-0.3,1-0.7,1.5-1l85.2-49.2c3.7-2.2,9.1-3.4,14.8-3.4 c5.7,0,11.1,1.2,14.8,3.4l85.2,49.2c0.5,0.3,1,0.6,1.5,1L162.5,384.8z M277.4,449.3c0,9.1-6.9,21.1-14.8,25.6l-85.2,49.2 c-1.4,0.8-3.1,1.5-5,2V401.8l103.3-59.6c1.1,3,1.7,5.9,1.7,8.6V449.3z" />
        <g opacity="0.4">
          <g fill="#00adef">
            <path d="M557,301.7l-18.1-10.5l16.5-9.5c12.9-7.4,23-24.9,23-39.8v-98.4c0-14.9-10.1-32.3-23-39.8 l-85.2-49.2c-6.2-3.6-14.4-5.6-23-5.6c-8.6,0-16.7,2-23,5.6L339,103.8c-12.9,7.4-23,24.9-23,39.8V242c0,14.9,10.1,32.3,23,39.8 l18.1,10.5l-16.5,9.5c-12.9,7.4-23,24.9-23,39.8v98.4c0,14.9,10.1,32.3,23,39.8l85.2,49.2c6.2,3.6,14.4,5.6,23,5.6 c8.6,0,16.7-2,23-5.6l85.2-49.2c12.9-7.4,23-24.9,23-39.8v-98.4C580,326.6,569.9,309.2,557,301.7z M560.3,134.9 c1.1,3,1.7,5.9,1.7,8.6V242c0,9.1-6.9,21.1-14.8,25.6l-24.6,14.2l-63.9,37.6L457,194.5L560.3,134.9z M347.2,117.9l85.2-49.2 c3.7-2.2,9.1-3.4,14.8-3.4c5.7,0,11.1,1.2,14.8,3.4l85.2,49.2c0.5,0.3,1,0.6,1.5,1l-101.5,58.6l-101.5-58.6 C346.2,118.5,346.7,118.2,347.2,117.9z M347.2,268.3c-7.9-4.5-14.8-16.5-14.8-25.6v-98.4c0,0,0,0,0,0v-0.7c0-2.7,0.6-5.7,1.7-8.6 l103.3,59.6v53.6h0v71.3c0,0-1.2,0.2-5-2L347.2,268.3z M439,516.7c0,0-1.2,0.2-5-2l-85.2-49.2C340.9,461,334,449,334,439.9v-98.4 c0-2.7,0.6-5.7,1.7-8.6L439,392.5V516.7z M448.8,375.4l-101.5-58.6c0.5-0.3,1-0.7,1.5-1l24.6-14.2l50.8,29.3 c6.2,3.6,14.4,5.6,23,5.6c8.6,0,16.7-2,23-5.6l52.4-30.3l26.3,15.2c0.5,0.3,1,0.6,1.5,1L448.8,375.4z M563.7,439.9 c0,9.1-6.9,21.1-14.8,25.6l-85.2,49.2c-1.4,0.8-3.1,1.5-5,2V392.5L562,332.8c1.1,3,1.7,5.9,1.7,8.6V439.9z" />
            <path d="M259.3,303.9l-85.2-49.2c-6.2-3.6-14.4-5.6-23-5.6c-8.6,0-16.7,2-23,5.6L43,303.9 c-12.9,7.4-23,24.9-23,39.8v98.4c0,14.9,10.1,32.3,23,39.8l85.2,49.2c6.2,3.6,14.4,5.6,23,5.6c8.6,0,16.7-2,23-5.6l85.2-49.2 c12.9-7.4,23-24.9,23-39.8v-98.4C282.3,328.8,272.2,311.3,259.3,303.9z M141.3,518.8c0,0-1.2,0.2-5-2l-85.2-49.2 c-7.9-4.5-14.8-16.5-14.8-25.6v-98.4c0-2.7,0.6-5.7,1.7-8.6l103.3,59.6V518.8z M151.1,377.6L49.6,319c0.5-0.3,1-0.7,1.5-1 l85.2-49.2c3.7-2.2,9.1-3.4,14.8-3.4c5.7,0,11.1,1.2,14.8,3.4l85.2,49.2c0.5,0.3,1,0.6,1.5,1L151.1,377.6z M266,442.1 c0,9.1-6.9,21.1-14.8,25.6l-85.2,49.2c-1.4,0.8-3.1,1.5-5,2V394.6L264.3,335c1.1,3,1.7,5.9,1.7,8.6V442.1z" />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Cubes
