import React from "react"

const BackGroundHoneyComb = () => {
  return (
    <div>
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 805.2 788.8"
      >
        <g opacity="0.24">
          <path
            d="M186,733.9v-81.5l68-40.8l68,40.8v81.5l-68,40.8L186,733.9z M320.2,653.4L254,613.7 l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V653.4z"
            opacity="0.5"
            fill="#00adef"
          />
          <polygon
            points="0,684.6 51.9,653.4 51.9,574 0,542.8 0,540.7 53.7,572.9 53.7,654.5 0,686.7"
            fill="#e6e6e6"
          />
          <polygon
            points="120.8,532.7 120.8,614.2 52.8,655 0,623.3 0,621.2 52.8,652.9 119,613.2 119,533.7 52.8,494 0,525.6 0,523.6 52.8,491.9"
            opacity="0.5"
            fill="#323232"
          />
          <path
            d="M521.5,292.2v-81.5l68-40.8l68,40.8v81.5l-68,40.8L521.5,292.2z M655.7,211.7L589.5,172l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V211.7z"
            fill="#e6e6e6"
          />
          <path
            d="M588.6,251.9v-81.5l68-40.8l68,40.8v81.5l-68,40.8L588.6,251.9z M722.7,171.4 l-66.2-39.7l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V171.4z"
            opacity="0.5"
            fill="#323232"
          />
          <path
            d="M588.6,332.4v-81.5l68-40.8l68,40.8v81.5l-68,40.8L588.6,332.4z M722.7,251.9l-66.2-39.7 l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V251.9z"
            fill="#969696"
          />
          <path
            d="M186.1,332.4v-81.5l68-40.8l68,40.8v81.5l-68,40.8L186.1,332.4z M320.2,251.9L254,212.2l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V251.9z"
            fill="#969696"
          />
          <path
            d="M186.1,251.9v-81.5l68-40.8l68,40.8v81.5l-68,40.8L186.1,251.9z M320.2,171.4L254,131.7l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V171.4z"
            fill="#e6e6e6"
          />
          <path
            d="M253.1,211.7v-81.5l68-40.8l68,40.8v81.5l-68,40.8L253.1,211.7z M387.3,131.2l-66.2-39.7 l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V131.2z"
            fill="#969696"
          />
          <path
            d="M119,211.7v-81.5l68-40.8l68,40.8v81.5l-68,40.8L119,211.7z M253.1,131.2l-66.2-39.7l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V131.2z"
            fill="#323232"
            opacity="0.5"
          />
          <path
            d="M521.5,694.7v-81.5l68-40.8l68,40.8v81.5l-68,40.8L521.5,694.7z M655.7,614.2l-66.2-39.7 l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V614.2z"
            fill="#c8c8c8"
          />
          <path
            d="M454.4,654.5v-81.5l68-40.8l68,40.8v81.5l-68,40.8L454.4,654.5z M588.6,574l-66.2-39.7 L456.2,574v79.5l66.2,39.7l66.2-39.7V574z"
            opacity="0.5"
            fill="#323232"
          />
          <path
            d="M253.1,614.2v-81.5l68-40.8l68,40.8v81.5l-68,40.8L253.1,614.2z M387.3,533.7 L321.1,494l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V533.7z"
            opacity="0.5"
            fill="#323232"
          />
          <path
            d="M186.1,493.4v-81.5l68-40.8l68,40.8v81.5l-68,40.8L186.1,493.4z M320.2,412.9L254,373.2l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V412.9z"
            fill="#969696"
          />
          <path
            d="M119,453.2v-81.5l68-40.8l68,40.8v81.5l-68,40.8L119,453.2z M253.1,372.7L186.9,333 l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V372.7z"
            opacity="0.5"
            fill="#323232"
          />
          <path
            d="M51.9,493.4v-81.5l68-40.8l68,40.8v81.5l-68,40.8L51.9,493.4z M186.1,412.9l-66.2-39.7l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V412.9z"
            fill="#c8c8c8"
          />
          <path
            d="M51.9,654.5v-81.5l68-40.8l68,40.8v81.5l-68,40.8L51.9,654.5z M186.1,574l-66.2-39.7L53.7,574v79.5 l66.2,39.7l66.2-39.7V574z"
            fill="#969696"
          />
          <path
            d="M51.9,412.9v-81.5l68-40.8l68,40.8v81.5l-68,40.8L51.9,412.9z M186.1,332.4l-66.2-39.7l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V332.4z"
            fill="#969696"
          />
          <path
            d="M119,372.7v-81.5l68-40.8l68,40.8v81.5l-68,40.8L119,372.7z M253.1,292.2l-66.2-39.7l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V292.2z"
            fill="#00adef"
          />
          <path
            d="M387.3,533.7v-81.5l68-40.8l68,40.8v81.5l-68,40.8L387.3,533.7z M521.5,453.2l-66.2-39.7 l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V453.2z"
            fill="#c8c8c8"
          />
          <path
            d="M253.1,533.7v-81.5l68-40.8l68,40.8v81.5l-68,40.8L253.1,533.7z M387.3,453.2l-66.2-39.7 l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V453.2z"
            fill="#646464"
          />
          <path
            d="M454.4,574v-81.5l68-40.8l68,40.8V574l-68,40.8L454.4,574z M588.6,493.4l-66.2-39.7l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V493.4z"
            fill="#969696"
          />
          <path
            d="M521.5,533.7v-81.5l68-40.8l68,40.8v81.5l-68,40.8L521.5,533.7z M655.7,453.2l-66.2-39.7 l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V453.2z"
            fill="#e6e6e6"
          />
          <path
            d="M521.5,614.2v-81.5l68-40.8l68,40.8v81.5l-68,40.8L521.5,614.2z M655.7,533.7L589.5,494l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V533.7z"
            fill="#e6e6e6"
          />
          <path
            d="M588.6,574v-81.5l68-40.8l68,40.8V574l-68,40.8L588.6,574z M722.7,493.4l-66.2-39.7l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V493.4z"
            fill="#969696"
          />
          <path
            d="M655.7,453.2v-81.5l68-40.8l68,40.8v81.5l-68,40.8L655.7,453.2z M789.8,372.7L723.6,333l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V372.7z"
            fill="#00adef"
          />
          <path
            d="M454.4,412.9v-81.5l68-40.8l68,40.8v81.5l-68,40.8L454.4,412.9z M588.6,332.4 l-66.2-39.7l-66.2,39.7v79.5l66.2,39.7l66.2-39.7V332.4z"
            opacity="0.5"
            fill="#323232"
          />
          <path
            d="M387.3,453.2v-81.5l68-40.8l68,40.8v81.5l-68,40.8L387.3,453.2z M521.5,372.7L455.3,333l-66.2,39.7 v79.5l66.2,39.7l66.2-39.7V372.7z"
            fill="#00adef"
          />
          <path
            d="M320.2,412.9v-81.5l68-40.8l68,40.8v81.5l-68,40.8L320.2,412.9z M454.4,332.4 l-66.2-39.7L322,332.4v79.5l66.2,39.7l66.2-39.7V332.4z"
            opacity="0.5"
            fill="#323232"
          />
          <path
            d="M451.7,331.9c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6 C453.3,335.5,451.7,333.9,451.7,331.9z"
            fill="#00adef"
          />
          <path
            d="M453.5,734.5c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8 C454.3,736.2,453.5,735.4,453.5,734.5z"
            fill="#00adef"
          />
          <path
            d="M111.4,533.2c0-4.7,3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5c0,4.7-3.8,8.5-8.5,8.5 C115.2,541.7,111.4,537.9,111.4,533.2z"
            fill="#00adef"
          />
          <path
            d="M650.7,613.7c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8c0,3.2-2.6,5.8-5.8,5.8 C653.3,619.5,650.7,616.9,650.7,613.7z"
            fill="#e6e6e6"
          />
          <path
            d="M521,130.7c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3 C521.6,132,521,131.4,521,130.7z"
            fill="#00adef"
          />
          <polygon
            points="0,201.5 51.9,170.4 51.9,90.9 0,59.8 0,57.7 53.7,89.9 53.7,171.4 0,203.6"
            opacity="0.5"
            fill="#323232"
          />
          <polygon
            points="0,362.6 51.9,331.4 51.9,251.9 0,220.8 0,218.7 53.7,250.9 53.7,332.4 0,364.6"
            opacity="0.5"
            fill="#323232"
          />
          <polygon
            points="120.8,210.7 120.8,292.2 52.8,333 0,301.3 0,299.2 52.8,330.9 119,291.2 119,211.7 52.8,172 0,203.6 0,201.5 52.8,169.9"
            fill="#969696"
          />
          <path
            d="M585.4,251.4c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4C587.2,255.5,585.4,253.7,585.4,251.4 z"
            fill="#00adef"
          />
          <path
            d="M718.3,331.9c0-3,2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4c0,3-2.4,5.4-5.4,5.4 C720.7,337.3,718.3,334.9,718.3,331.9z"
            fill="#00adef"
          />
          <path
            d="M585.9,492.9c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6 C587.5,496.5,585.9,494.9,585.9,492.9z"
            fill="#00adef"
          />
          <path
            d="M247.8,372.2c0-3.5,2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3c0,3.5-2.8,6.3-6.3,6.3 C250.6,378.4,247.8,375.6,247.8,372.2z"
            fill="#00adef"
          />
          <path
            d="M115.8,211.2c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4C117.6,215.2,115.8,213.4,115.8,211.2 z"
            fill="#00adef"
          />
          <path
            d="M115.8,452.7c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4C117.6,456.7,115.8,454.9,115.8,452.7 z"
            fill="#00adef"
          />
          <path
            d="M248.7,130.7c0-3,2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4c0,3-2.4,5.4-5.4,5.4 C251.1,136,248.7,133.6,248.7,130.7z"
            fill="#00adef"
          />
          <path
            d="M117.6,50.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2 C118.6,52.4,117.6,51.4,117.6,50.2z"
            fill="#00adef"
          />
          <path
            d="M183.4,9.9c0-2,1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6S183.4,11.9,183.4,9.9z"
            fill="#00adef"
          />
          <path
            d="M250,533.2c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4C251.8,537.2,250,535.4,250,533.2z"
            fill="#00adef"
          />
          <path
            d="M383.3,694.2c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9c0,2.7-2.2,4.9-4.9,4.9 C385.5,699.1,383.3,696.9,383.3,694.2z"
            fill="#00adef"
          />
          <path
            d="M720.5,734.5c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1 C721.9,737.6,720.5,736.2,720.5,734.5z"
            fill="#00adef"
          />
          <path
            d="M178.9,653.9c0-4.4,3.6-8.1,8-8.1c4.4,0,8,3.6,8,8.1c0,4.4-3.6,8.1-8,8.1 C182.5,662,178.9,658.4,178.9,653.9z"
            fill="#00adef"
          />
          <path
            d="M518.8,613.7c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6 C520.4,617.3,518.8,615.7,518.8,613.7z"
            fill="#00adef"
          />
          <path
            d="M381.1,452.7c0-4,3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2c0,4-3.2,7.2-7.2,7.2S381.1,456.6,381.1,452.7z"
            fill="#00adef"
          />
          <path
            d="M451.7,251.4c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6 C453.3,255,451.7,253.4,451.7,251.4z"
            fill="#00adef"
          />
          <path
            d="M49.6,331.9c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1 C51,335.1,49.6,333.7,49.6,331.9z"
            fill="#00adef"
          />
        </g>
      </svg>
    </div>
  )
}

export default BackGroundHoneyComb
